import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from '@jarvis/react-portal-addons';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LoaderWidget from './shared/LoaderWidget';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import resources from './assets/locale/index';
import App from './App';
import { Card } from './styles';

const Notifications = ({ type, ...props }) => {
  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  const localization = props.shell?.v1?.localization;
  const locale = localization?.locale;
  const uniqueUserKey = getUniqueUserKey(locale);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: uniqueUserKey,
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale, uniqueUserKey]);

  if (fetchingLD) {
    return type === 'SMBSmartNotification'
      ? <Card appearance="dropShadow" content={<LoaderWidget fullScreen />} />
      : <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <RootProvider localization={localization} resources={resources}>
        <App
          {...props}
          type={type}
          uniqueUserKey={uniqueUserKey}
        />
      </RootProvider>
    </Container>
  );
};

Notifications.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  useMock: PropTypes.bool,
};

Notifications.defaultProps = {
  shell: {},
  type: null,
  useMock: false,
};

export default Notifications;
