import React, { Suspense, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import GenericThemeProvider from './shared/GenericThemeProvider';
import ToastProvider from './components/ToastProvider';

const App = ({ type, uniqueUserKey, ...rest }) => {
  const {
    shell,
  } = rest;

  const {
    v1: {
      authProvider,
      events,
      localization,
      navigation,
      store,
    } = {
      authProvider: null,
      events: null,
      navigation: null,
      store: null,
    },
  } = shell;

  const NotificationBell = React.lazy(() => import('./components/NotificationBell'));
  const SmartNotification = React.lazy(() => import('./components/SmartNotification'));

  const getComponent = useCallback(props => {
    switch (type) {
      case 'SMBSmartNotification':
        return (
          <Suspense fallback={<LoaderWidget fullscreen />}>
            <SmartNotification
              {...props}
              authProvider={authProvider}
              events={events}
              locale={localization.locale}
              navigation={navigation}
              store={store}
            />
          </Suspense>

        );
      default:
        return (
          <Suspense fallback={<LoaderWidget fullscreen />}>
            <NotificationBell
              {...props}
              authProvider={authProvider}
              events={events}
              locale={localization.locale}
              navigation={navigation}
              uniqueUserKey={uniqueUserKey}
              store={store}
            />
          </Suspense>
        );
    }
  }, [authProvider, events, localization.locale, navigation, store, type, uniqueUserKey]);

  return (
    <GenericThemeProvider>
      <ToastProvider>
        {getComponent(rest)}
      </ToastProvider>
    </GenericThemeProvider>
  );
};

App.defaultProps = {
  rest: {},
  type: null,
  uniqueUserKey: null,
};

App.propTypes = {
  rest: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  uniqueUserKey: PropTypes.string,
};

export default App;
